import { APP_IDS } from '@Terra/shared/shared-lib';
import { TimeStandardFormatsFromApi } from '../user-preference.config';
import { ErrorData, Groupmanagement, PreferenceOptions, UserPreferences, UserPreferencesFormats } from './../models/userpreferences.model';
import { UserPreferencesActionTypes, UserPreferencesActions } from './user-preferences.actions';

/**
 * Interface for the 'Userprferences' data used in
 *  - UserprferencesState, and
 *  - userprferencesReducer
 */
export interface UserPreferencesData {
  dataList: UserPreferencesState;
}

/**
 * Interface to the part of the Store containing UserprferencesState
 * and other information related to UserprferencesData.
 */

//set initial model interface
export interface UserPreferencesState {
  error: ErrorData;
  showMessageBox: boolean;
  errorMessage: string;
  successResponse: boolean;
  statusCode: number;
  message: string;
  closeDialog: boolean;
  prefUpdated: any;
  userpreferences: UserPreferences[];
  groupList: Groupmanagement[];
  userpreferencesFormats: UserPreferencesFormats[];
  preferenceOptions: PreferenceOptions;
  unitFormats: any;
  savePreferenceFailed: boolean;
}

//set initial state
export const initialState: UserPreferencesState = {
  error: null,
  showMessageBox: false,
  errorMessage: null,
  statusCode: null,
  successResponse: false,
  message: null,
  closeDialog: null,
  prefUpdated: null,
  userpreferences: [],
  groupList: [],
  userpreferencesFormats: [],
  preferenceOptions: null,
  unitFormats: null,
  savePreferenceFailed: false
};

//function to update state
// eslint-disable-next-line complexity
export function UserPreferencesReducer(state = initialState, action: UserPreferencesActions): UserPreferencesState {
  switch (action.type) {
    case UserPreferencesActionTypes.UserpreferencesSavedSuccessfully: {
      // preference success need to maintain the standard format in store
      const payloadUcid = action.payload.payloadData.ucid;
      let timeFormatData = action.payload.payloadData.timeFormat;
      if (action.payload.appId === APP_IDS.EROUTES && ['24 hour', '12 hour'].includes(timeFormatData)) {
        timeFormatData = TimeStandardFormatsFromApi[timeFormatData];
      }
      if (action.payload.appId === APP_IDS.PITMANAGEMENT) {
        action.payload.payloadData.ucid = payloadUcid ? payloadUcid : null;
      }
      return {
        ...state,
        prefUpdated: { ...action.payload.payloadData, timeFormat: timeFormatData }
      };
    }
    case UserPreferencesActionTypes.ResetFlags: {
      return {
        ...state,
        prefUpdated: null,
        error: null
      };
    }
    case UserPreferencesActionTypes.UserpreferencesSave: {
      return { ...state, userpreferences: action.payload };
    }
    case UserPreferencesActionTypes.ErrorAction: {
      return {
        ...state,
        error: action.payload,
        errorMessage: action.payload ? 'Some error occured' : 'Some error Occurred'
      };
    }
    case UserPreferencesActionTypes.UserPreferenceSaveFailed: {
      return {
        ...state,
        savePreferenceFailed: action.payload
      };
    }
    case UserPreferencesActionTypes.GetPreferenceOptionsSuccess: {
      return { ...state, preferenceOptions: action.payload, unitFormats: action.payload.systemOfMeasurements };
    }
    default:
      return state;
  }
}
