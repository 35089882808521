<app-user-preferences
  data-automation-id="up-usrpreflib"
  [data]="dataToPreferences"
  [appId]="appId"
  [ucidSelectionDropDown]="ucidSelectionDropDown"
  [defaultPreferenceCP]="defaultPreferenceCP"
  [allowToClose]="allowToClose"
  (modalClosed)="preferenceClosed($event)"
  (modalChangeAccountOpened)="changeAccountOpened($event)"
  (updateUserPreferences)="updatePreferences($event)"
>
</app-user-preferences>
