import { SharedTranslateLoaderModule } from '@Terra/shared/shared-translate-loader';
import { SpinnerModule } from '@Terra/shared/widgets/spinner';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CatButtonModule } from '@cat-digital-workspace/shared-ui-core/button';
import { CcDropdownModule } from '@cat-digital-workspace/shared-ui-core/dropdown';
import { CcInputFieldModule } from '@cat-digital-workspace/shared-ui-core/input-field';
import { CcRadioModule } from '@cat-digital-workspace/shared-ui-core/radio';
import { CcTooltipModule } from '@cat-digital-workspace/shared-ui-core/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UserPreferencesEffects } from './+state/user-preferences.effects';
import { UserPreferencesReducer, initialState as userPreferencesInitialState } from './+state/user-preferences.reducer';
import { UserPreferencesService } from './user-preference.service';
import { UserPreferencesContainerComponent } from './user-preferences-container/user-preferences-container.component';
import { UserPreferencesComponent } from './user-preferences/user-preferences.component';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SpinnerModule,
    CcInputFieldModule,
    CcRadioModule,
    CatButtonModule,
    CcDropdownModule,
    TranslocoModule,
    CcTooltipModule,
    StoreModule.forFeature('userPref', UserPreferencesReducer, {
      initialState: userPreferencesInitialState
    }),
    EffectsModule.forFeature([UserPreferencesEffects]),
    SharedTranslateLoaderModule
  ],
  declarations: [UserPreferencesContainerComponent, UserPreferencesComponent],
  exports: [UserPreferencesContainerComponent],
  providers: [UserPreferencesService]
})
export class UserPreferencesModule {}
