import { PreferenceValue } from '@Terra/shared/shared-lib';

export class UserPreferencesRequest {
  dateFormat: string;
  timeFormat: string;
  unit: string;
  autoRefresh: string;
  language: string;
  remember: boolean;
  ucid: string;
  decimalSeparator: string;
  defaultPreferenceCP = false;
  favoriteSites: string;

  constructor(obj: UserPreferencesRequest) {
    this.dateFormat = obj.dateFormat;
    this.timeFormat = obj.timeFormat;
    this.unit = obj.unit;
    this.autoRefresh = obj.autoRefresh;
    this.remember = obj.remember;
    this.ucid = obj?.ucid;
    this.favoriteSites =
      obj.favoriteSites && (typeof obj.favoriteSites === 'string' ? obj.favoriteSites : JSON.stringify(obj.favoriteSites));

    if (obj?.language) {
      this.language = obj?.language;
    }
    if (obj?.decimalSeparator) {
      this.decimalSeparator = obj?.decimalSeparator;
    }
  }
}

export interface Groupmanagement {
  id: number;
  ucid: number;
  name: string;
  description: string;
  parentID: number;
  groups: ISubGroup[];
}

export interface ISubGroup {
  id: number;
  ucid: number;
  name: string;
  description: string;
  parentID: number;
  groups: ISubGroup[];
}

export const UserRoleId = {
  SuperUser: 16
};
export const RootUser = {
  id: 1,
  parentId: 0
};

export interface PreferenceValueData {
  language: PreferenceValue;
  dateFormat: PreferenceValue;
  timeFormat: PreferenceValue;
  unit: PreferenceValue;
  decimal?: PreferenceValue;
}

export const preferenceValue: PreferenceValue = {
  fieldValue: '',
  fieldConflictValue: ''
};
export const preferenceValueDefault: PreferenceValueData = {
  language: preferenceValue,
  unit: preferenceValue,
  dateFormat: preferenceValue,
  timeFormat: preferenceValue,
  decimal: preferenceValue
};
