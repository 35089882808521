/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MessageBar } from '@cat-digital-workspace/shared-ui-core/message';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { getHeliosPreferencesData } from '@Terra/pitmanagement/app-store';
import {
  APP_IDS,
  CP_USER_PREFERENCE_VALUE,
  HideAppSpinner,
  PREFERENCE_FIELD_ORDER,
  ShowAppSpinner,
} from '@Terra/shared/shared-lib';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { HeliosPreference, Preference } from '@Terra/shared/widgets/interface';
import { GetPreferenceOptions, ResetFlags, UserpreferencesSave } from '../+state/user-preferences.actions';
import { UserPreferencesInput } from '../models/userpreferences.model';
import { UserPreferencesState } from './../+state/user-preferences.reducer';
import { PreferenceOptions, PreferenceValue, UserPreferenceOption } from './../models/userpreferences.model';
import { PREFERENCE_FIELDS, TOASTER_CONFIG, USER_PREF_CONSTANTS } from './../user-preference.config';

@Component({
  selector: 'app-user-preferences-container',
  templateUrl: './user-preferences-container.component.html'
})
export class UserPreferencesContainerComponent implements OnInit, OnDestroy {
  message: string;
  colorMessage: string;
  @Input() allowToClose = true;
  @Input() appId;
  @Input() defaultPreferenceCP = false;
  @Input() ucidSelectionDropDown = [];
  @Output() preferenceClose: EventEmitter<boolean> = new EventEmitter();
  @Output() showChangeAccountModal = new EventEmitter();
  @Output() prefUpdate = new EventEmitter<boolean>();

  dataToPreferences: UserPreferencesInput = {
    groupListData: null,
    userPreferencesData: null,
    userPreferencesAutoRefreshData: null,
    userPreferencesDateFormatData: null,
    userPreferencesTimeFormatData: null,
    userPreferencesUnitFormatData: null,
    userPreferencesNumberFormatData: null,
    authorizationData: null,
    userLanguageconstants: null,
    appPreference: null,
    heliosPreference: null,
    preferenceOptions: null
  };
  heliosSupportedApps = APP_IDS;
  subscription = new Subscription();
  constructor(
    private readonly store: Store<UserPreferencesState>,
    public messageBar: MessageBar,
    private readonly translocoService: TranslocoService
  ) { }

  ngOnInit() {
    this.subscription.add(
      this.store
        .select(getHeliosPreferencesData)
        .pipe(filter(res => !!res))
        .subscribe((preference: HeliosPreference) => {
          //for selection
          this.dataToPreferences.appPreference = preference.appPreference;
          this.dataToPreferences.heliosPreference = preference.globalPreference;
          const getResponse = JSON.parse(JSON.stringify(preference.appPreference)); //timeformatchange - need to remove this change
          const timeFormatData = getResponse.timeFormat ? (getResponse.timeFormat.includes('24') ? '24 hour' : '12 hour') : null; //timeformatchange - need to remove this change
          this.dataToPreferences.userPreferencesData = { ...getResponse, timeFormat: timeFormatData }; //timeformatchange - set direct response alias getResponse
        })
    );

    this.subscription.add(
      this.store
        .select((state: any) => state.userPref.preferenceOptions)
        .subscribe((preferenceOptions: PreferenceOptions) => {
          if (preferenceOptions) {
            this.dataToPreferences.userLanguageconstants = this.filterPreferenceOptions(preferenceOptions.languages);
            this.dataToPreferences.userPreferencesDateFormatData = this.filterPreferenceOptions(preferenceOptions.dateFormats);
            this.dataToPreferences.userPreferencesTimeFormatData = this.filterPreferenceOptions(preferenceOptions.timeFormats);
            this.dataToPreferences.userPreferencesUnitFormatData = this.filterPreferenceOptions(preferenceOptions.systemOfMeasurements);
            if (this.appId === this.heliosSupportedApps.PITMANAGEMENT) {
              this.sortPreferenceFields();
            }
            this.dataToPreferences.userPreferencesNumberFormatData = this.filterPreferenceOptions(preferenceOptions.numberFormats);
            this.dataToPreferences.preferenceOptions = preferenceOptions;
            this.dataToPreferences = { ...this.dataToPreferences };
          } else {
            this.store.dispatch(new GetPreferenceOptions());
          }
        })
    );

    this.subscription.add(
      this.store
        .select((state: any) => state.userPref.error)
        .subscribe(response => {
          if (response) {
            this.store.dispatch(new HideAppSpinner());

            const messageType =
              response.type === USER_PREF_CONSTANTS.MESSAGE.TYPE.WARNING ? TOASTER_CONFIG.TYPE.WARNING : TOASTER_CONFIG.TYPE.ERROR;
            const msg = this.translocoService.translate(response.message);
            this.messageBar.open(msg, messageType, [], {
              hostType: TOASTER_CONFIG.HOST_TYPE.OVERLAY,
              duration: TOASTER_CONFIG.TOASTER_LIFE_TIME,
              horizontalPosition: TOASTER_CONFIG.H_POSITION,
              verticalPosition: TOASTER_CONFIG.V_POSITION
            });
          }
        })
    );

    this.subscription.add(
      this.store
        .select((state: any) => state.userPref.prefUpdated)
        .subscribe(response => {
          if (response) {
            this.store.dispatch(new HideAppSpinner());
            this.preferenceClosed(false);
          }
        })
    );
  }

  /**
   * Filter eRoutes supported options from global list
   * Also convert preference options into name, value pair
   * @param list - Array of preference value
   * @return Array of name, value pair
   */
  filterPreferenceOptions(list: PreferenceValue[]): UserPreferenceOption[] {
    const SupportedOptions = CP_USER_PREFERENCE_VALUE;
    if (list && list.length) {
      return list
        .filter(item => !!SupportedOptions[item.key])
        .map(item => ({ name: item.value, label: item.value, value: SupportedOptions[item.key] }));
    }
    return [];
  }

  updatePreferences(event) {
    const preferenceCollection = { ...event };
    let previousUcid;
    let saveOtherFields = true;
    if (this.appId === this.heliosSupportedApps.PITMANAGEMENT) {
      previousUcid = this.dataToPreferences.userPreferencesData.ucid;
    }
    if (previousUcid) {
      saveOtherFields = this.comparePreferences(preferenceCollection);
    }
    const saveUcid = previousUcid !== preferenceCollection.ucid;
    if (this.defaultPreferenceCP || saveUcid || saveOtherFields) {
      this.store.dispatch(new ShowAppSpinner());
      this.store.dispatch(
        new UserpreferencesSave(preferenceCollection, {
          appId: this.appId,
          saveUcid,
          saveOtherFields,
          defaultPreferenceCP: this.defaultPreferenceCP
        })
      );
    } else {
      this.preferenceClosed(false);
    }
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.preferenceClosed(false);
  }

  preferenceClosed(event) {
    this.preferenceClose.emit(event);
  }

  changeAccountOpened(event) {
    this.showChangeAccountModal.emit(event);
  }

  /** To check if user has made any change in preference in fields other group */
  comparePreferences(current: Preference) {
    for (const i of PREFERENCE_FIELDS) {
      if (this.dataToPreferences.userPreferencesData[i] !== current[i]) {
        return true;
      }
    }
    return false;
  }

  /** To preserve the current order of options in apps */
  sortPreferenceFields() {
    this.dataToPreferences.userPreferencesTimeFormatData = this.sortFieldOrder(
      PREFERENCE_FIELD_ORDER.TIME_FORMAT,
      this.dataToPreferences.userPreferencesTimeFormatData
    );
    this.dataToPreferences.userPreferencesUnitFormatData = this.sortFieldOrder(
      PREFERENCE_FIELD_ORDER.UNITS,
      this.dataToPreferences.userPreferencesUnitFormatData
    );
    const langList = Object.values(CP_USER_PREFERENCE_VALUE).slice(8);
    this.dataToPreferences.userLanguageconstants = this.sortFieldOrder(langList, this.dataToPreferences.userLanguageconstants);
  }

  /** Sorts any field as per the order we specify */
  sortFieldOrder(LIST: any[], FIELD_LIST: UserPreferenceOption[]) {
    const FormattedList = [];
    LIST.forEach(each => {
      const formatData = FIELD_LIST.find(format => format.value === each);
      formatData && FormattedList.push(formatData);
    });
    return FormattedList;
  }

  ngOnDestroy() {
    this.store.dispatch(new ResetFlags());
    this.subscription.unsubscribe();
  }
}
