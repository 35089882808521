import { HeliosUserPreference } from '@Terra/shared/widgets/interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { HeliosCustomPreference, HeliosGlobalPreferenceResponse } from './models/userpreferences.model';
import { ApiConfig, NODE_SERVER } from './user-preference.config';

@Injectable()
export class UserPreferencesService {
  private envObj: any;
  constructor(private readonly http: HttpClient, private readonly store: Store<any>) {
    this.store
      .select((state: any) => state.environment)
      .pipe()
      .subscribe(r => (this.envObj = r));
  }

  constructUrl(apiName: string) {
    return this.envObj.auth_ms_baseURL + apiName;
  }

  constructUrlNode(method, apiName?: string) {
    let url = '';
    if (apiName) {
      url = this.envObj.nodeBaseURL + method + NODE_SERVER.QUERYSTRING_QM + NODE_SERVER.HANDLER_URL + NODE_SERVER.QUERYSTRING_EQ + apiName;
    } else {
      url = this.envObj.nodeBaseURL + method;
    }
    return url;
  }

  loadTimeFormat(): Observable<any> {
    const url = this.constructUrlNode(NODE_SERVER.METHOD_NAME.GET, this.constructUrl(ApiConfig.webApi.loadUserTimeFormat_url));
    const params = new HttpParams().append('canShowSpinner', 'true');
    return this.http.get<any>(url, { params }).pipe(retry(1));
  }
  loadDateFormat(): Observable<any> {
    const url = this.constructUrlNode(NODE_SERVER.METHOD_NAME.GET, this.constructUrl(ApiConfig.webApi.loadUserDateFormat_url));
    return this.http.get<any>(url).pipe(retry(1));
  }
  loadUnitFormat(): Observable<any> {
    const url = this.constructUrlNode(NODE_SERVER.METHOD_NAME.GET, this.constructUrl(ApiConfig.webApi.loadUserUnitFormat_url));
    return this.http.get<any>(url).pipe(retry(1));
  }
  loadAutoRefresh(): Observable<any> {
    const url = this.constructUrlNode(NODE_SERVER.METHOD_NAME.GET, this.constructUrl(ApiConfig.webApi.loadUserAutoRefresh_url));
    return this.http.get<any>(url).pipe(retry(1));
  }
  loadUserLanguage(): Observable<any> {
    const url = this.constructUrlNode(NODE_SERVER.METHOD_NAME.GET, this.constructUrl(ApiConfig.webApi.loadUserLanguage_url));
    return this.http.get<any>(url).pipe(retry(1));
  }

  updateHeliosCustomPreference(payload: HeliosCustomPreference, appId: number): Observable<any> {
    const handlerURL = this.envObj.heliosBaseURL + ApiConfig.webApi.patchHeliosCustomPreference[appId];
    const finalUrl = this.constructUrlNode(NODE_SERVER.METHOD_NAME.PATCH);
    const body = {
      params: payload,
      handlerURL,
      customheaders: JSON.stringify({ 'Content-Type': 'application/merge-patch+json' })
    };
    return this.http.patch(finalUrl, body);
  }

  /**
   * Use this method to update user preference to Helios
   * @param payload Helios preference object
   */
  updateHeliosUserPreference(payload: HeliosUserPreference): Observable<any> {
    const handlerURL = this.envObj.heliosBaseURL + ApiConfig.webApi.postHeliosUserPreference;
    const finalUrl = this.constructUrlNode(NODE_SERVER.METHOD_NAME.POST);
    const body = {
      params: payload,
      handlerURL,
      customheaders: JSON.stringify({ 'Content-Type': 'application/json' })
    };
    return this.http.post<any>(finalUrl, body);
  }

  /**
   * Use this method to fetch preference options and defaults from Helios
   */
  getHeliosGlobalPreference(): Observable<HeliosGlobalPreferenceResponse> {
    const url = this.envObj.heliosBaseURL + ApiConfig.webApi.loadHeliosGlobalPreference;
    const params = new HttpParams().append('canShowSpinner', 'true');
    const values = url.split('?');
    const body = {
      handlerURL: values[0],
      payload: values[1],
      post2Get: true
    };
    return this.http.post<HeliosGlobalPreferenceResponse>(NODE_SERVER.METHOD_NAME.POST, body, { params }).pipe(retry(1));
  }
}
