import { Action } from '@ngrx/store';
import { ErrorData, PreferenceOptions } from '../models/userpreferences.model';

export enum UserPreferencesActionTypes {
  LoadUserpreferences = '[Userpreferences] Load Data',
  LoadUserpreferencesGroupList = '[Userpreferences] load Groups',
  UserpreferencesSave = '[Userpreferences] Data Saved',

  UserpreferencesLoaded = '[Userpreferences] Data Loaded',
  UserpreferencesGroupListLoaded = '[Userpreferences] GroupsLoaded',
  ErrorAction = '[UserPreferences] Error',
  UserpreferencesSavedSuccessfully = '[UserPreferences] Userprferences Saved Successfully',
  UserPreferenceSaveFailed = '[UserPreferences] User preference save failed',
  ResetFlags = '[UserPreferences] reset the flag',
  GetPreferenceOptions = '[UserPreferences] Get preference options',
  GetPreferenceOptionsSuccess = '[UserPreferences] Get preference options success'
}

export class ErrorAction implements Action {
  readonly type = UserPreferencesActionTypes.ErrorAction;
  constructor(public payload: ErrorData) {}
}
export class LoadUserpreferences implements Action {
  readonly type = UserPreferencesActionTypes.LoadUserpreferences;
  constructor() {}
}

export class UserpreferencesLoaded implements Action {
  readonly type = UserPreferencesActionTypes.UserpreferencesLoaded;
  constructor(public payload: any) {}
}

export class LoadUserpreferencesGroupList implements Action {
  readonly type = UserPreferencesActionTypes.LoadUserpreferencesGroupList;
  constructor() {}
}

export class UserpreferencesGroupListLoaded implements Action {
  readonly type = UserPreferencesActionTypes.UserpreferencesGroupListLoaded;
  constructor(public payload: any) {}
}

export class UserpreferencesSave implements Action {
  readonly type = UserPreferencesActionTypes.UserpreferencesSave;
  constructor(public payload: any, public options: any) {}
}

export class UserpreferencesSavedSuccessfully implements Action {
  readonly type = UserPreferencesActionTypes.UserpreferencesSavedSuccessfully;
  constructor(public payload: any, public appId?: any) {}
}
export class UserPreferenceSaveFailed implements Action {
  readonly type = UserPreferencesActionTypes.UserPreferenceSaveFailed;
  constructor(public payload: any) {}
}

export class ResetFlags implements Action {
  readonly type = UserPreferencesActionTypes.ResetFlags;
  constructor() {}
}

export class GetPreferenceOptions implements Action {
  readonly type = UserPreferencesActionTypes.GetPreferenceOptions;
}

export class GetPreferenceOptionsSuccess implements Action {
  readonly type = UserPreferencesActionTypes.GetPreferenceOptionsSuccess;
  constructor(public payload: PreferenceOptions) {}
}

export type UserPreferencesActions =
  | ErrorAction
  | LoadUserpreferences
  | UserpreferencesLoaded
  | UserpreferencesSave
  | LoadUserpreferencesGroupList
  | UserpreferencesGroupListLoaded
  | UserpreferencesSavedSuccessfully
  | ResetFlags
  | GetPreferenceOptions
  | GetPreferenceOptionsSuccess
  | UserPreferenceSaveFailed;
