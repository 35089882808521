import { MessageBarHorizontalPosition, MessageBarVerticalPosition } from '@cat-digital-workspace/shared-ui-core/message';

export const USER_PREF_CONSTANTS = {
  MESSAGE: {
    COLORS: {
      ERROR: 'red',
      SUCCESS: 'green',
      WARNING: 'yellow'
    },
    TYPE: {
      ERROR: 'ERROR',
      WARNING: 'WARNING'
    }
  }
};

export const LABELS_MESSAGES = {
  COMMON_ERROR: 'app.errorMessage.process_ERR',
  USERPREFERENCES_UPDATE_FAILURE: 'USERPREFERENCES_UPDATE_FAILURE'
};

export const NODE_SERVER = {
  HANDLER_URL: 'handlerURL',
  PARAMS: 'params',
  METHOD_NAME: {
    GET: '/apigetdata',
    POST: '/apipostdata',
    PUT: '/apiputdata',
    DELETE: '/apideletedata',
    PATCH: '/apipatchdata'
  },
  QUERYSTRING_QM: '?',
  QUERYSTRING_AMP: '&',
  QUERYSTRING_EQ: '='
};

export enum TimeStandardFormatsFromApi {
  '24 hour' = 'HH:MM:SS 24hr',
  '12 hour' = 'HH:MM:SS AM/PM'
}

export const ApiConfig = {
  // need to revisit  apiEnv: environment.envName,
  webApi: {
    userPreferencesFormats_url: '/bins/amjom',
    putPreferences_url: '/api/v2/users/preferences',
    loadUserTimeFormat_url: '/api/v2/general/formats/times',
    loadUserDateFormat_url: '/api/v2/general/formats/dates',
    loadUserUnitFormat_url: '/api/v1/general/formats/units',
    loadUserAutoRefresh_url: '/api/v1/general/formats/autorefresh',
    loadUserLanguage_url: '/api/v1/general/formats/languages',
    ROLE_ACCESS_URL: '/api/v1/users/menupermissions',
    patchHeliosCustomPreference: {
      1: 'customUserPreferences/namespace/eroutes?privacyLevel=private',
      3: 'customUserPreferences/namespace/catproductivity?privacyLevel=private'
    },
    loadHeliosGlobalPreference: 'globalPreferences?countryCode=US',
    postHeliosUserPreference: 'globalPreferences/user'
  }
};

export const horizontalPosition: MessageBarHorizontalPosition = 'center';
export const verticalPosition: MessageBarVerticalPosition = 'top';
export const TOASTER_CONFIG = {
  TYPE: {
    SUCCESS: 'success',
    ERROR: 'error',
    INFO: 'info',
    WARNING: 'warning'
  },
  TOASTER_LIFE_TIME: 5000,
  HOST_TYPE: {
    OVERLAY: 'overlay',
    INLINE: 'inline'
  },
  H_POSITION: horizontalPosition,
  V_POSITION: verticalPosition
};
export const TOOLTIP_POSITION = {
  TOP: 'top',
  TOP_RIGHT: 'top-right'
};

export const PREFERENCE_FIELDS = ['language', 'dateFormat', 'timeFormat', 'unit', 'decimalSeparator'];

export const ROOT_PARTY_NAME = 'Root';

export const PREF_FIELD_MAPPING = {
  language: { name: 'language', app: 'language', helios: 'language', optionName: 'languages', showHelios: true },
  dateFormat: { name: 'dateFormat', app: 'dateFormat', helios: 'dateFormat', optionName: 'dateFormats' },
  unit: { name: 'unit', app: 'unit', helios: 'systemOfMeasurement', optionName: 'systemOfMeasurements' },
  timeFormat: { name: 'timeFormat', app: 'timeFormat', helios: 'timeFormat', optionName: 'timeFormats' },
  decimal: { name: 'decimal', app: 'decimalSeparator', helios: 'numberFormat', optionName: 'numberFormats' }
};
