<div class="cc-modal">
  <div class="cc-modal-header" data-automation-id="upl-title">
    <div class="cc-modal-title">{{ 'userPreference.title' | transloco }}</div>
    <div cc-tooltip="{{ 'app.close' | transloco }}" class="cc-modal-close" [hidden]="!allowToClose" (click)="userPreferencesModalClose()">
      <img class="cc-close-semi" src="/assets/images/closeSemi.svg" alt="close modal" />
    </div>
  </div>

  <div class="cc-modal-content">
    @if (data.userPreferencesData) {
    <form #userForm="ngForm">
      <div class="group-pre-data route-prefrance">
        @if (showConflictAlertMessage) {
        <div class="alert-container">
          <div class="alert-text-container">
            <img src="userpreferences/images/warning-circle.svg" alt="warning" />
            <span class="alert-text"> {{ 'userPreference.IMPORTANT_ALERT' | transloco }}</span>
          </div>
          <div class="alert-message">
            <span> {{ 'userPreference.ALERT_INFO' | transloco }}</span>
          </div>
        </div>
        }
        <ng-container>
          <div class="groups-container">
            <div class="default-account-details">
              <div class="default-account-name">
                <span class="userPreference-label">{{ 'USERPREFERENCES_GROUP' | transloco }}</span>
                <span
                  cc-tooltip=""
                  [template]="defaultAccTemplate"
                  [tooltipPosition]="tooltipPosition"
                  appClass="-up-tootip-container"
                  class="userPreference-name"
                  [innerHTML]="ucidSelectionDropDown?.length ? ucidSelectionDropDown?.[0]?.label : '--'"
                ></span>
              </div>
              <div class="change-account-div">
                <span (click)="showChangeAccountModal()">{{ 'CHANGE_ACCOUNT' | transloco }}</span>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="language-container">
          <label for="Language" class="preferences-label" fxLayoutAlign="left"
            >{{ 'userPreference.languageLabel' | transloco }}
            @if (preferenceValueData?.language.fieldConflictValue) {
            <span cc-tooltip="" [template]="langConflictTemplate" appClass="-up-tootip-container" [tooltipPosition]="tooltipPosition"
              ><img class="warning-image" src="userpreferences/images/warning-circle.svg" alt="{{ 'userPreference.WARNING' | transloco }}"
            /></span>
            }
          </label>
          <cc-dropdown
            name="language-dropdown"
            [label]="'userPreference.languageLabel' | transloco"
            [dataSource]="data.userLanguageconstants"
            [initSelectedItem]="[selectedLanguage]"
            (selectionChange)="onLanguageChange($event.currentSelection[0])"
            [id]="'userpreferences-language-dropdown'"
            [isTheme]="'2.0'"
          >
            <ng-template cc-dropdown-option let-item="item">
              <span class="cc-option-content"> {{ item?.label }} </span>
            </ng-template>
          </cc-dropdown>
        </div>
        <div class="date-format-container">
          <label data-automation-id="upl-date-formatlbl" for="date_Format" class="preferences-label" fxLayoutAlign="left"
            >{{ 'userPreference.dateLabel' | transloco }}
            @if (preferenceValueData?.dateFormat.fieldConflictValue) {
            <span cc-tooltip="" [template]="dateConflictTemplate" appClass="-up-tootip-container" [tooltipPosition]="tooltipPosition"
              ><img class="warning-image" src="userpreferences/images/warning-circle.svg" alt="{{ 'userPreference.WARNING' | transloco }}"
            /></span>
            }</label
          >
          <cc-dropdown
            name="dateFormat-dropdown"
            [label]="'userPreference.dateLabel' | transloco"
            [dataSource]="data.userPreferencesDateFormatData"
            [initSelectedItem]="[selectedDateFormat]"
            (selectionChange)="onDateFormatChange($event.currentSelection[0])"
            [id]="'dateFormat-dropdown'"
            [isTheme]="'2.0'"
          >
            <ng-template cc-dropdown-option let-item="item">
              <span class="cc-option-content"> {{ item?.label | transloco }} </span>
            </ng-template>
          </cc-dropdown>
        </div>
        <label data-automation-id="upl-time-formatlbl" for="time_format" class="preferences-label"
          >{{ 'userPreference.timeLabel' | transloco }}@if (preferenceValueData?.timeFormat.fieldConflictValue) {
          <span cc-tooltip="" [template]="timeConflictTemplate" appClass="-up-tootip-container" [tooltipPosition]="tooltipPosition"
            ><img class="warning-image" src="userpreferences/images/warning-circle.svg" alt="{{ 'userPreference.WARNING' | transloco }}"
          /></span>
          }</label
        >
        @if (data.userPreferencesTimeFormatData && data.userPreferencesTimeFormatData.length > 0) {
        <div class="radio-btn">
          <cc-radio-group [(ngModel)]="timeFormat" name="time">
            @for (category of data.userPreferencesTimeFormatData; track null) {
            <cc-radio-button [value]="category.value" [checked]="isChecked === false">
              {{ category.name | transloco }}
            </cc-radio-button>
            }
          </cc-radio-group>
        </div>
        }
        <div>
          <label
            data-automation-id="upl-units-label"
            for="unit"
            class="preferences-label"
            [ngClass]="{ 'unit-cls': appId === supportedAppIds.PITMANAGEMENT, 'unit-label': appId === supportedAppIds.EROUTES }"
            >{{ 'userPreference.unitLabel' | transloco }}@if (preferenceValueData?.unit.fieldConflictValue) {
            <span cc-tooltip="" [template]="unitConflictTemplate" appClass="-up-tootip-container" [tooltipPosition]="tooltipPosition"
              ><img class="warning-image" src="userpreferences/images/warning-circle.svg" alt="{{ 'userPreference.WARNING' | transloco }}"
            /></span>
            }
          </label>
          @if (appId !== supportedAppIds.PITMANAGEMENT) {
          <cc-dropdown
            name="uom-dropdown"
            [label]="'userPreference.unitLabel' | transloco"
            [dataSource]="data.userPreferencesUnitFormatData"
            [initSelectedItem]="[selectedUOM]"
            (selectionChange)="onUOMChange($event.currentSelection[0])"
            [id]="'uom-dropdown'"
            [isTheme]="'2.0'"
          >
            <ng-template cc-dropdown-option let-item="item">
              <span class="cc-option-content"> {{ item?.label | transloco }} </span>
            </ng-template>
          </cc-dropdown>
          }
          <!--Unit of measurement radio button for pitmanagement -->
          @if (appId === supportedAppIds.PITMANAGEMENT) { @if (data.userPreferencesUnitFormatData &&
          data.userPreferencesUnitFormatData.length > 0) {
          <div id="units-radio">
            <cc-radio-group [(ngModel)]="unit" data-automation-id="upl-units" name="unit">
              @for (category of data.userPreferencesUnitFormatData; track null) {
              <cc-radio-button [value]="category.value" [checked]="isChecked === false">
                {{ category.name | transloco }}
              </cc-radio-button>
              }
            </cc-radio-group>
          </div>
          } }
        </div>
        @if (appId === supportedAppIds.PITMANAGEMENT) {
        <label data-automation-id="upl-decimal-label" for="decimal" class="preferences-label unit-label"
          >{{ 'userPreference.DecimalLabel' | transloco }}
          @if (preferenceValueData.decimal.fieldConflictValue) {
          <span cc-tooltip="" [template]="decimalConflictTemplate" appClass="-up-tootip-container" [tooltipPosition]="tooltipPosition"
            ><img class="warning-image" src="userpreferences/images/warning-circle.svg" alt="{{ 'userPreference.WARNING' | transloco }}"
          /></span>
          }
        </label>
        @if (data.userPreferencesNumberFormatData?.length > 0) {
        <div id="decimal-radio">
          <cc-radio-group [(ngModel)]="decimal" data-automation-id="upl-decimal" name="decimal">
            @for (category of data.userPreferencesNumberFormatData; track null) {
            <cc-radio-button [value]="category.value" [checked]="isChecked === false">
              {{ category.name | transloco }}
            </cc-radio-button>
            }
          </cc-radio-group>
        </div>
        } }
        <div class="footer-text">
          <span> {{ 'PREFERENCE_APP_APPLICABLE_TEXT' | transloco }}</span>
        </div>
      </div>
    </form>
    }
  </div>

  <div class="cc-modal-footer">
    <button
      [setTheme]="true"
      cc-btn-secondary
      cc-btn-large
      data-automation-id="upl-cancel"
      (click)="userPreferencesModalClose()"
      [hidden]="!allowToClose"
    >
      {{ 'userPreference.cancelBtn' | transloco }}
    </button>
    <button
      [setTheme]="true"
      cc-btn-primary
      cc-btn-large
      data-automation-id="upl-save"
      (click)="saveUserPreferences()"
      type="button"
      class="save-btn"
      [disabled]="!data.preferenceOptions || !ucidSelectionDropDown?.length"
    >
      {{ 'userPreference.saveBtn' | transloco }}
    </button>
  </div>
</div>

<ng-template #langConflictTemplate>
  <span
    class="up-tooltip-wrap"
    [innerHTML]="'userPreference.conflict_message_language' | transloco: { value: preferenceValueData.language.fieldConflictValue }"
  >
  </span>
</ng-template>
<ng-template #dateConflictTemplate>
  <span
    class="up-tooltip-wrap"
    [innerHTML]="'userPreference.conflict_message_dateformat' | transloco: { value: preferenceValueData.dateFormat.fieldConflictValue }"
  >
  </span>
</ng-template>
<ng-template #timeConflictTemplate>
  <span
    class="up-tooltip-wrap"
    [innerHTML]="'userPreference.conflict_message_timeformat' | transloco: { value: preferenceValueData.timeFormat.fieldConflictValue }"
  >
  </span>
</ng-template>
<ng-template #unitConflictTemplate>
  <span
    class="up-tooltip-wrap"
    [innerHTML]="'userPreference.conflict_message_unit' | transloco: { value: preferenceValueData.unit.fieldConflictValue }"
  >
  </span>
</ng-template>
<ng-template #decimalConflictTemplate>
  <span
    class="up-tooltip-wrap"
    [innerHTML]="'userPreference.conflict_message_decimal' | transloco: { value: preferenceValueData.decimal.fieldConflictValue }"
  >
  </span>
</ng-template>
<ng-template #defaultAccTemplate>
  <span class="up-tooltip-wrap" [innerHTML]="ucidSelectionDropDown?.length ? ucidSelectionDropDown?.[0]?.label : '--'"> </span>
</ng-template>
