import { APP_IDS, CP_USER_PREFERENCE_HELIOS_VALUE, USER_PREFERENCE_HELIOS_VALUE } from '@Terra/shared/shared-lib';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import { forkJoin, of } from 'rxjs';
import { catchError, delay, map } from 'rxjs/operators';
import { USER_PREF_CONSTANTS } from '../user-preference.config';
import { UserPreferencesService } from '../user-preference.service';
import { LABELS_MESSAGES } from './../user-preference.config';
import {
  ErrorAction,
  GetPreferenceOptions,
  GetPreferenceOptionsSuccess,
  UserPreferenceSaveFailed,
  UserPreferencesActionTypes,
  UserpreferencesSave,
  UserpreferencesSavedSuccessfully
} from './user-preferences.actions';
import { UserPreferencesState } from './user-preferences.reducer';

@Injectable()
export class UserPreferencesEffects {
  constructor(private readonly actions$: Actions, private readonly userPreferencesService: UserPreferencesService) {}

  UserprferencesSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferencesActionTypes.UserpreferencesSave),
      fetch({
        run: (action: UserpreferencesSave, state: UserPreferencesState) => {
          let customPreferenceUpdate = of({});
          let userPreferenceUpdate = of({});
          if (action.options.saveUcid || action.options.defaultPreferenceCP) {
            customPreferenceUpdate = this.userPreferencesService.updateHeliosCustomPreference(
              { ucid: action.payload.ucid, favoriteSites: action.payload.favoriteSites },
              action.options.appId
            );
          }
          if (action.options.saveOtherFields || action.options.defaultPreferenceCP) {
            const config = action.options.appId === APP_IDS.EROUTES ? USER_PREFERENCE_HELIOS_VALUE : CP_USER_PREFERENCE_HELIOS_VALUE;
            const preference = {
              language: config[action.payload.language],
              dateFormat: config[action.payload.dateFormat],
              timeFormat: config[action.payload.timeFormat],
              systemOfMeasurement: config[action.payload.unit]
            };
            if (action.options.appId === APP_IDS.PITMANAGEMENT) {
              preference['numberFormat'] = config[action.payload.decimalSeparator];
            }
            userPreferenceUpdate = this.userPreferencesService.updateHeliosUserPreference(preference);
          }
          return forkJoin({ customPreferenceUpdate, userPreferenceUpdate }).pipe(
            delay(2000),
            map(_ => new UserpreferencesSavedSuccessfully({ payloadData: action.payload, appId: action.options.appId })),
            catchError(_ => of(new UserPreferenceSaveFailed(true)))
          );
        }
      })
    )
  );

  getPreferenceOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferencesActionTypes.GetPreferenceOptions),
      fetch({
        run: (action: GetPreferenceOptions, state: UserPreferencesState) =>
          this.userPreferencesService.getHeliosGlobalPreference().pipe(
            map(
              response =>
                new GetPreferenceOptionsSuccess({
                  languages: response.properties.languages,
                  dateFormats: response.properties.dateFormats,
                  timeFormats: response.properties.timeFormats,
                  systemOfMeasurements: response.properties.systemOfMeasurements,
                  numberFormats: response.properties.numberFormats
                })
            ),
            catchError(error =>
              of(
                new ErrorAction({
                  type: USER_PREF_CONSTANTS.MESSAGE.TYPE.ERROR,
                  message: LABELS_MESSAGES.COMMON_ERROR
                })
              )
            )
          )
      })
    )
  );
}
