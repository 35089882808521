/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
import { APP_IDS, CP_DEFAULT_USER_GLOBAL_PREFERENCE_VALUE, CP_USER_PREFERENCE_VALUE, SharedLibService } from '@Terra/shared/shared-lib';
import { scopeLoader } from '@Terra/shared/shared-translate-loader';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CcModal } from '@cat-digital-workspace/shared-ui-core/modal';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { UserPreferenceOption } from '../models/userpreferences.model';
import { PREF_FIELD_MAPPING, TOOLTIP_POSITION } from '../user-preference.config';
import { PreferenceValueData, UserPreferencesRequest, preferenceValueDefault } from './user-preferences.model';
@Component({
  selector: 'app-user-preferences',
  templateUrl: './user-preferences.component.html',
  styleUrls: ['./user-preferences.component.scss'],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'userPreference',
        loader: scopeLoader((lang, root) => import(`../${root}/${lang}.json`))
      }
    }
  ]
})
export class UserPreferencesComponent implements OnInit, OnChanges {
  rememberMe = false;

  @Input() appId;
  @Input() showDialog: boolean;
  @Input() data: any;
  @Input() allowToClose = true;
  @Input() defaultPreferenceCP = false;
  @Input() ucidSelectionDropDown = [];
  @Output() modalClosed: EventEmitter<boolean> = new EventEmitter();
  @Output() modalChangeAccountOpened = new EventEmitter();
  @Output() updateUserPreferences: EventEmitter<UserPreferencesRequest> = new EventEmitter();

  dateFormat: any;
  timeFormat = '';
  autoRefresh: any;
  unit: any;
  decimal: any;
  userPreferencesData$;
  groupListData$;
  groupListDataTree;
  userPreferencesTimeFormatData$;
  userPreferencesDateFormatData$;
  userPreferencesUnitFormatData$;
  isChecked: any;
  ucid: string;

  isClosable = false;
  userLanguagePreference: string;
  selectedLanguage: UserPreferenceOption;
  selectedDateFormat: UserPreferenceOption;
  selectedUOM: UserPreferenceOption;
  preferenceValueData: PreferenceValueData = preferenceValueDefault;
  showConflictAlertMessage = false;
  supportedAppIds = APP_IDS;
  appConfig = {};
  //CP variables
  tooltipPosition: string;

  constructor(private readonly sharedLibService: SharedLibService, public modal: CcModal, public breakpointObserver: BreakpointObserver) {
    this.breakpointObserver
      .observe([Breakpoints.HandsetLandscape, Breakpoints.HandsetPortrait, Breakpoints.TabletPortrait])
      .subscribe(result => {
        this.tooltipPosition = result.matches ? TOOLTIP_POSITION.TOP : TOOLTIP_POSITION.TOP_RIGHT;
      });
  }

  ngOnInit() {
    this.showDialog = true;
    this.isClosable = false;
    this.appConfig = CP_USER_PREFERENCE_VALUE;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.updateUserPreferencesData();
    }
  }

  updateUserPreferencesData(): void {
    if (this.data && this.data.userPreferencesData && this.data.preferenceOptions) {
      this.showDialog = true;
      this.setSelectedUserPreferences(this.data.userPreferencesData);
    }
  }

  getValuesFromKey(key: string, data: { key: string; value: string }[], showHelios = false): string {
    const result = data.find(value => value.key === key);
    return !showHelios && this.appConfig[result?.key] ? this.appConfig[result?.key] : result?.value;
  }

  loadPreferenceValue() {
    const showConflictMsg = () =>
      !!(
        this.preferenceValueData.language.fieldConflictValue ||
        this.preferenceValueData.unit.fieldConflictValue ||
        this.preferenceValueData.dateFormat.fieldConflictValue ||
        this.preferenceValueData.timeFormat.fieldConflictValue ||
        this.preferenceValueData.decimal.fieldConflictValue
      );
    for (const i in PREF_FIELD_MAPPING) {
      this.preferenceValueData[PREF_FIELD_MAPPING[i].name] = this.sharedLibService.getValueFromPreferenceMatrix(
        this.data?.appPreference[PREF_FIELD_MAPPING[i].app],
        this.data?.heliosPreference[PREF_FIELD_MAPPING[i].helios],
        this.data?.appPreference.isHeliosMigrated
      );
    }
    for (const i in PREF_FIELD_MAPPING) {
      this.preferenceValueData[PREF_FIELD_MAPPING[i].name].fieldConflictValue =
        this.preferenceValueData[PREF_FIELD_MAPPING[i].name].fieldConflictValue &&
        this.getValuesFromKey(
          this.preferenceValueData[PREF_FIELD_MAPPING[i].name].fieldConflictValue,
          this.data.preferenceOptions[PREF_FIELD_MAPPING[i].optionName],
          PREF_FIELD_MAPPING[i].showHelios
        );
    }
    this.showConflictAlertMessage = showConflictMsg();
  }

  saveUserPreferences() {
    const obj: UserPreferencesRequest = {
      dateFormat: this.dateFormat,
      timeFormat: this.timeFormat,
      unit: this.unit,
      autoRefresh: this.autoRefresh,
      language: this.userLanguagePreference,
      remember: this.rememberMe,
      ucid: this.ucidSelectionDropDown?.[0]?.data?.ucid,
      decimalSeparator: this.supportedAppIds.PITMANAGEMENT ? this.decimal : null,
      defaultPreferenceCP: false,
      favoriteSites: this.data.userPreferencesData.favoriteSites
    };
    const userPreferencesRequest = new UserPreferencesRequest(obj);
    this.updateUserPreferences.emit(userPreferencesRequest);
  }
  setSelectedUserPreferences(selectedUserPreferences) {
    this.loadPreferenceValue();
    this.dateFormat =
      this.preferenceValueData.dateFormat.fieldValue ?? CP_USER_PREFERENCE_VALUE[CP_DEFAULT_USER_GLOBAL_PREFERENCE_VALUE.dateFormat];
    this.timeFormat =
      this.preferenceValueData.timeFormat.fieldValue ?? CP_USER_PREFERENCE_VALUE[CP_DEFAULT_USER_GLOBAL_PREFERENCE_VALUE.timeFormat];
    this.rememberMe = selectedUserPreferences.remember;
    this.unit =
      this.preferenceValueData.unit.fieldValue ?? CP_USER_PREFERENCE_VALUE[CP_DEFAULT_USER_GLOBAL_PREFERENCE_VALUE.systemOfMeasurement];
    this.autoRefresh = selectedUserPreferences.autoRefresh;
    this.setSelectedLanguage();
    this.selectedDateFormat = this.data.userPreferencesDateFormatData.find(element => element.value === this.dateFormat);
    this.selectedUOM = this.data.userPreferencesUnitFormatData.find(element => element.value === this.unit);
    this.decimal =
      this.preferenceValueData.decimal.fieldValue ?? CP_USER_PREFERENCE_VALUE[CP_DEFAULT_USER_GLOBAL_PREFERENCE_VALUE.numberFormat];
  }

  setSelectedLanguage(): void {
    const currentLanguage = this.preferenceValueData.language.fieldValue; //set voc lang in case of new user without pref. lang.
    this.userLanguagePreference = currentLanguage;
    this.selectedLanguage = this.data.userLanguageconstants.find(element => element.value === currentLanguage);
  }

  userPreferencesModalClose() {
    this.showDialog = false;
    this.modalClosed.emit(this.showDialog);
  }
  showChangeAccountModal() {
    this.modalChangeAccountOpened.emit({
      ucid: this.ucidSelectionDropDown?.[0]?.data?.ucid,
      showDialog: true
    });
  }

  onLanguageChange(event: any) {
    this.userLanguagePreference = event.value;
  }

  onDateFormatChange(event: any) {
    this.dateFormat = event.value;
  }

  onUOMChange(event: any) {
    this.unit = event.value;
  }
}
